<template>
  <div class="newsAdd">
    <main v-if="news" role="main" class="ml-sm-auto px-4">

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/news">News</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ news.name ? news.name : 'Unnamed' }}</li>
        </ol>
      </nav>

      <div class="row row-cols-1 row-cols-md-12">
        <div class="col-md-4 mb-2 name-column">
          <input class="form-control form-control-sm" v-model="news.name" placeholder="" required>
          <div class="form-text">Name</div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-12">
        <div class="col-md-4 mb-2 subject-column">
          <input class="form-control form-control-sm" v-model="news.subject" placeholder="" required>
          <div class="form-text">Subject / title</div>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-md-12">
        <div class="col-md-4 mb-2 placeholders-column">
          <input id="placeholders-label" class="form-control form-control-sm" type="text"
            v-model="news.placeholdersString" :placeholder="todayDatePlaceholder()">
          <div class="form-text">Placeholders <a target="_blank"
              href="https://www.notion.so/8f6313ee3b714f329b214e27a0b40a9a?v=9eaed5aeb0e348f4b77ab01c1ae0b4cb&pvs=4"><i
                class="bi bi-question-circle"></i></a></div>
        </div>
      </div>

      <button class="btn btn-secondary btn-sm"
        :disabled="!news.name || !news.subject || !news.city" @click="add()">
        <i class="bi bi-plus"></i> Add
      </button>
    </main>
  </div>
</template>

<script setup>
import { api, authHeaders } from '../api';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

import { useRouter } from 'vue-router';
const router = useRouter();

import { ref, computed, onMounted } from 'vue';
import parsePlaceholders from '../placeholders';

const news = ref({
  id: uuidv4(),
  city: user.city_id,
  account_id: user.account_id,
  notes: '',
  name: '',
  subject: '',
  created: {},
  last_modified: {},
  placeholdersString: '',
  placeholders: [],
  markdown: `# 🚀 Getting Started

Replace this content with the body of the newsletter. This text is written in [Markdown](https://www.markdownguide.org/basic-syntax/), a lightweight markup language. The Foundry will transform the Markdown into HTML and insert it in the newsletter Template. To insert local data and graphics in the text, you can use *Mixins*, code blocs that get interpreted when the newsletter is published. Read on to learn more about publishing neighborhood newsletters.

## Markdown

This content is written in Markdown. Markdown is a lightweight markup language that you can use to add formatting elements to plaintext text documents. See [Markdown](https://www.markdownguide.org/basic-syntax/) to learn the basics. You want to save your edits using the save button in the toolbar above. If you forget to save and navigate away, you will be reminded that you have unsaved changes.

## Template

The template is the HTML page template used to generate the published newsletters. You can see the template for your newsroom at the bottom of this page by opening the tray marked *Template*.

## Mixins

**Mixins** are placeholders for data and graphics interpreted when the newsletters are generated.

To find Mixins to use in this newsletter, go to the Mixins tab. You can use the *Copy* button on the upper left of the Mixin tab form to cut and paste  the Mixin in this newsletter. Use the paste command depending on your operating system: \`Ctrl+V\` in Windows and \`⌘+V\` in Mac.

For example, selecting the *Neighborhood* dataset and *Neighborhood names* category will produce the following Mixin that displays the name of the neighborhood for which this content is published:

TODO: add mixin code based on city

If we select *citywide* in the Neighborhoods dialog box, the Mixin will display *citywide*, the name associated with the *citywide* neighborhood.

TODO: add mixin code based on city

## Placeholders

Placeholders are typically used in the template to set static text to be replaced when publishing. The syntax is as follows: \`@identifier@=value\`. For example, if we set \`@date@=Jan 1, 2023\` in the settings tray below in this newsletter, \`@date@\`, if present in the template, will be replaced with \`Jan 1, 2023\` when published.

Placeholders can also be used in newsletters. For example, @date@ will be replaced with Jan 1, 2023, if the placeholder \`@date@=Jan 1, 2023\` is defined in the Settings tray at the bottom of the page.

Try publishing this newsletter to see how the Mixins and Placeholders are replaced.

@date@

## Prints

Prints are published newsletters, which consist of one HTML document per neighborhood. The Print tab at the top of this page lets you configure for which neighborhoods this newsletter will be published. It is convenient when authoring newsletters to publish for a few neighborhoods. Try publishing this newsletter to get familiar with creating prints.`,
  placeholders: '@date@=Jan 1, 2023'
});


const todayDatePlaceholder = function () {
  return '@date@=' + dayjs().format('MMM D, YYYY');
};

const add = async () => {
  news.value.created = news.value.last_modified = {
    timestamp: Date.now(),
    user: {
      name: user.name,
      email: user.email
    }
  };

  news.value.placeholders = parsePlaceholders(news.value.placeholdersString);

  await api.post('news', { news: news.value }, authHeaders());

  router.push("/news");
};

</script>

<style scoped>
.name-column {
  min-width: 380px;
}

.subject-column {
  min-width: 380px;
}

.placeholders-column {
  min-width: 380px;
}
</style>