// src/stores/user.js
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useUserStore = defineStore('user', () => {
  const user = ref(loadUserFromLocalStorage());

  // Watch the user property for changes and update localStorage
  watch(user, (newUser) => {
    saveUserToLocalStorage(newUser);
  }, { deep: true });

  async function setUser(user) {
    this.user = {
      role: user.role,
      name: user.name,
      email: user.email,
      city_id: user.city_id,
      city_name: user.city_name,
      can_send: user.can_send,
      account_id: user.account_id,
      account_name: user.account_name
    };
  };

  // Load user from localStorage on store initialization
  function loadUserFromLocalStorage() {
    const userJson = localStorage.getItem('user');
    return userJson ? JSON.parse(userJson) : {};
  }

  // Save user to localStorage
  function saveUserToLocalStorage(newUser) {
    localStorage.setItem('user', JSON.stringify(newUser));
  }  

  return {
    user,
    setUser,
  };
});
