<template>
  <div v-if="theme">
    <div v-for="(item, index) in theme.range" v-bind:key="index" class="scheme">
      <strong>{{ themePropertyName(theme.range, item) }}</strong>
      <div class="swatch">
        <div v-for="(val, index2) in item" v-bind:key="index2" :title="val" :style="'background: ' + val + ';'"></div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div id="bar-vis"></div>
      </div>
      <div class="col">
        <div id="line-vis"></div>
      </div>
      <div class="col">
        <div id="map-vis"></div>
      </div>
    </div>

    <button class="btn btn-sm btn-secondary mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2"
      aria-expanded="false" aria-controls="collapse-2">Theme file</button>
    <div class="collapse" id="collapse-2">
      <div class="card">
        <div class="scrolled-text">
          <pre><code class="trace">{{ theme }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { api, authHeaders } from '../api';

const props = defineProps({
  account_id: {
    type: String,
    required: true
  }
});

const sampleBarSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
  description: 'A simple bar chart with embedded data.',
  height: 200,
  width: 400,
  data: {
    values: [
      { a: 'A', b: 28 },
      { a: 'B', b: 55 },
      { a: 'C', b: 43 },
      { a: 'D', b: 91 },
      { a: 'E', b: 81 },
      { a: 'F', b: 53 },
      { a: 'G', b: 19 },
      { a: 'H', b: 87 },
      { a: 'I', b: 52 },
    ],
  },
  mark: 'bar',
  encoding: {
    x: { field: 'a', type: 'nominal', axis: { labelAngle: 0 } },
    y: { field: 'b', type: 'quantitative' },
    color: { field: 'name', type: 'nominal', "legend": null },
  },
};

const sampleLineSpec = {
  $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
  description: 'A simple line chart with embedded data.',
  height: 200,
  width: 400,
  data: {
    values: [
      { x: '2020', y: 19, label: 'a' },
      { x: '2024', y: 5, label: 'a' },
      { x: '2020', y: 7, label: 'b' },
      { x: '2024', y: 2, label: 'b' },
      { x: '2020', y: 2, label: 'c' },
      { x: '2024', y: 3, label: 'c' },
      { x: '2020', y: 14, label: 'd' },
      { x: '2024', y: 4, label: 'd' },
      { x: '2020', y: 24, label: 'e' },
      { x: '2024', y: 8, label: 'e' },
      { x: '2020', y: 54, label: 'f' },
      { x: '2024', y: 4, label: 'f' },
    ],
  },
  mark: { type: 'line' },
  encoding: {
    x: { field: 'x', type: 'ordinal', axis: { title: null } },
    y: { field: 'y', type: 'quantitative', axis: { title: 'y', anchor: 'end' }, scale: { domain: [0, 54 * 1.2] } },
    color: { field: 'label', type: 'nominal' },
  }
};

const sampleMapSpec = {
  layer: [
    {
      mark: { type: 'geoshape', fill: 'lightgray', stroke: 'white' },
      height: 200,
      projection: { type: 'naturalEarth1' },
      width: 400,
    },
    {
      mark: { type: 'geoshape', stroke: 'white' },
      encoding: {
        color: {
          type: 'quantitative',
          field: 'freq',
          legend: { title: 'Crosstown in the World' },
          scale: { scheme: 'goldorange' },
        },
        tooltip: [
          { type: 'nominal', field: 'country' },
          { type: 'nominal', field: 'id' },
          { type: 'quantitative', field: 'freq' },
        ],
      },
      projection: { type: 'naturalEarth1' },
      transform: [
        {
          lookup: 'id',
          from: {
            data: { name: 'data-freq' },
            key: 'id',
            fields: ['country', 'freq'],
          },
        },
      ],
    },
  ],
  data: {
    url: 'https://cdn.jsdelivr.net/npm/vega-datasets@v1.29.0/data/world-110m.json',
    format: { feature: 'countries', type: 'topojson' },
  },
  $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
  datasets: {
    'data-freq': [
      { country: 'Jordan', id: 400, freq: 1 },
      { country: 'Canada', id: 124, freq: 2 },
      { country: 'USA', id: 840, freq: 3 },
      { country: 'Mexico', id: 484, freq: 1 },
      { country: 'UAE', id: 784, freq: 1 },
      { country: 'Cambodia', id: 116, freq: 1 },
      { country: 'Puerto Rico', id: 630, freq: 1 },
      { country: 'Chile', id: 152, freq: 1 },
      { country: 'Barbados', id: 52, freq: 1 },
      { country: 'Iran', id: 364, freq: 1 },
      { country: 'Ireland', id: 372, freq: 1 },
      { country: 'Kuwait', id: 414, freq: 1 },
      { country: 'Saudi Arabia', id: 682, freq: 1 },
      { country: 'Uganda', id: 800, freq: 1 },
    ],
  },
};

import embed from 'vega-embed';
import * as vegaLite from 'vega-lite';

const theme = ref();

function themePropertyName(prop, value) {
  for (var i in prop) {
    if (prop[i] == value) {
      return i;
    }
  }
  return false;
};

onMounted(async () => {
  const result = await api.get('mixins/theme', { params: { account_id: props.account_id } });
  const plainTheme = result.data;
  theme.value = JSON.stringify(sampleLineSpec, null, 2);

  //https://vega.github.io/vega-lite/usage/compile.html
  let spec = vegaLite.compile(sampleBarSpec, { config: plainTheme }).spec;
  embed('#bar-vis', spec, { actions: false });

  spec = vegaLite.compile(sampleLineSpec, { config: plainTheme }).spec;
  embed('#line-vis', spec, { actions: false });

  spec = vegaLite.compile(sampleMapSpec, { config: plainTheme }).spec;
  embed('#map-vis', spec, { actions: false });
});
</script>

<style scoped>
/** Color Schemes. */
.scheme {
  margin-bottom: 1em;
}

.scheme .swatch div {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 1px solid white;
}

.scheme .swatch div[title] {
  border: 1px solid #ddd;
}

.scheme .swatch strong {
  font-weight: normal;
  display: inline-block;
  vertical-align: 50%;
  margin-left: 12px;
  width: 108px;
}

.scheme svg {
  display: block;
  width: 556px;
  height: 28px;
  margin-top: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ddd;
}

.scheme.continuous {
  width: 556px;
}

.scheme.continuous .toggle {
  display: none;
  float: right;
  margin-top: 0.5em;
  font-size: 0.7em;
  color: black;
}

.scheme.continuous:hover .toggle {
  display: block;
}

code.trace {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: normal;

  /* min-height: 100px; */
  margin: 0 0 0 0px;
  padding: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  font-size: 0.72em;
  /* color: white; */
  /* background-color: rgb(52, 58, 64); */
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
}
</style>