<template>
  <div v-if="!news" class="alert alert-danger">
    There is no News associated. This print cannot be published.
  </div>

  <div v-if="print">
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 row-cols-lg-4 row-cols-xl-4">
      <div class="col mb-2">
        <div class="form-group" id="name-label">
          <label for="name-input">Name</label>
          <input class="form-control" type="text" id="name-input" v-model="print.name" placeholder="Name"
            @input="onInput">
        </div>
      </div>

      <div v-if="news" class="col-md-6 mb-2">
        <label name="neighborhood-select">Neighborhoods to publish</label>
        <Multiselect v-model="print.partitions" :options="neighborhoodOptions" track-by="value" label="text"
          mode="tags" :close-on-select="false" @select="onInput" @deselect="onInput" :class="darkMode ? 'dark-mode' : ''">
        </Multiselect>
      </div>
    </div>

    <div class="alert alert-info" role="alert">
      <div v-if="template" class="col mb-2">
        Template: <a target="_blank" :href="template.url">{{ template.name }}</a>
      </div>

      <ul v-if="news.placeholders.length > 0">
        <span>Placeholders: </span>
        <li v-for="placeholder in news.placeholders" :key="placeholder">
          <span v-for="(value, key) in placeholder">
            <template v-if="key === 'key'">
              <span class="key">{{ value }}</span> set to
            </template>
            <template v-else>
              <span class="value">{{ value }}</span>
            </template>
          </span>
        </li>
      </ul>
      <p v-else>No placeholders specified</p>
    </div>

    <button v-if="news" @click="onPublish()" class="btn btn-secondary btn-sm my-2">
      <span v-if="publishing" class="spinner-border spinner-border-sm"></span>
      <i v-else class="bi bi-files"></i>
      {{ publishing ? 'Publishing...' : 'Publish' }}
    </button>

    <div v-if="publishing">
      <div v-if="print.publications && neighborhoodMap"  class="mt-2">
        publishing {{ publishedCount }} of {{ print.publications.partitions.length }}
        <div class="progress me-2 w-25">
          <div class="progress-bar" role="progressbar"
            :style="{ width: ((publishedCount / print.publications.partitions.length) * 100) + '%' }">
            {{ ((publishedCount / print.publications.partitions.length) * 100).toFixed(1) }}%
          </div>
        </div>
        <div>
          <span v-for="(item, index) in print.publications.partitions" :key="index">
            <a v-if="item.index != ''" target="_blank" :href="printsURL + item.index" class="me-2">
              <span ref="tooltipContainer" class="tooltip-container" data-bs-toggle="tooltip" :title="'Title: ' + news.subject">
              {{ neighborhoodMap.get(item.id) }}
              </span>
            </a>
            <span v-else class="me-2">
              <span ref="tooltipContainer" class="tooltip-container" data-bs-toggle="tooltip" :title="'Title: ' + news.subject">
              {{ neighborhoodMap.get(item.id) }}
              </span>
            </span>
          </span>
        </div>
      </div>
      <div v-else>
        publishing...
      </div>
    </div>
    <div v-else-if="print && print.publications && neighborhoodMap" class="mt-2">
      {{ publishedCount }} of {{ print.publications.partitions.length }}
      neighborhoods published by
      <span v-if="print.publications && print.publications.created">
        <a :href="'mailto:' + print.publications.created.user.email">
          {{ print.publications.created.user.name }}
        </a>
      </span>
      <span> on {{ formatDate(print.publications.created.timestamp) }}</span>
      <div>
        <span v-for="(item, index) in print.publications.partitions" :key="index">
          <a v-if="item.index != ''" target="_blank" :href="printsURL + item.index" class="me-2">
            <span ref="tooltipContainer" class="tooltip-container" data-bs-toggle="tooltip" :title="'Title: ' + news.subject">
              {{ neighborhoodMap.get(item.id) }}
            </span>
          </a>
          <span v-else class="me-2">
            <span ref="tooltipContainer" class="tooltip-container" data-bs-toggle="tooltip" :title="'Title: ' + news.subject">
              {{ neighborhoodMap.get(item.id) }}
            </span>
          </span>
        </span>
      </div>
    </div>

    <div v-if="print">
      <div class="collapse mt-2" id="collapse-print-info">
        <div class="card">
          <div class="card-body">
            <div><b>print</b></div>
            <code class="trace">{{ print }}</code>
            <div v-if="printResults">
              <div><b>print results</b></div>
              <code class="trace">{{ printResults }}</code>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-2" v-if="printResults && printResults.error">
      <pre><code class="trace">🚨 {{ printResults.error.message }}
      {{ JSON.stringify(printResults.error.config) }}
      {{ printResults.error.stack }}</code></pre>
    </div>

    <!-- <div v-if="print" class="card mt-3 border-danger p-3">
      <h4 class="text-danger">Danger zone</h4>
      <div class="my-2">
        Deleting is forever, to proceed enter <q>{{ print.id }}</q>
      </div>
      <form class="form-inline">
        <input class="form-control mb-2" type="text" v-model="deletePrintCode" placeholder="Enter print id">
      </form>

      <button :disabled="deletePrintCode !== print.id" class="btn btn-danger" @click="onDeletePrint()">Delete</button>
    </div> -->
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch} from 'vue';

const emit = defineEmits(['update:modelValue']);

import { debounce } from 'lodash';
const debouncedSave = debounce(onSave, 500); // Debounce delay (in milliseconds) for save


const onInput = () => {
  dirty.value = true;
  debouncedSave(); // Invoke the debounced onSave function
};

async function onSave() {
  print.value.last_modified = { user: { email: user.email, name: user.name }, timestamp: Date.now() };
  await api.post('prints', print.value, authHeaders());
  dirty.value = false;
  emit('update:modelValue', print.value);
}

import { api, authHeaders } from '../api';
import dayjs from 'dayjs';

import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css'

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

const props = defineProps({
  news: {
    type: [Object, null],  // Allow object or null
    default: null  // Set the default value to null
  },
  modelValue: Object,
});

const multiSelectTheme = ref('dark-mode');
const darkMode = ref(true);

const print = ref();
const news = ref();
const dirty = ref(false);
//const deletePrintCode = ref('');
const printsURL = ref(import.meta.env.VITE_API_URL + 'publications/'); //XXX: put in .env);
const neighborhoodMap = ref();
const publishedCount = ref(0);
const printResults = ref();
const publishing = ref(false);
const timer = ref();
const template = ref();

const neighborhoodOptions = ref([]);

const formatDate = (date) => (date ? dayjs(date).format(import.meta.env.VITE_DATETIME_FORMAT) : 'NA');
const tooltipContainer = ref(null);

function openNewWindow() {
  const route = router.value.resolve({
    name: 'newsEdit',
    params: { id: print.value.news_id },
  });
  window.open(route.href, '_blank');
}

async function onPublish() {
  if (publishing.value) {
    publishing.value = false;
    printResults.value = undefined;
    getPrint();
    clearInterval(timer.value);
    return;
  }

  publishing.value = true;
  print.value.publications = undefined;
  timer.value = setInterval(getPrint, 1000);

  const result = await api.post('prints/publish', { print: print.value }, authHeaders());  
  printResults.value = result.data;
  publishing.value = false;
  await getPrint();
  clearInterval(timer.value);
}

async function getPrint() {
  const result = await api.get('prints/' + print.value.id, authHeaders());
  print.value = result.data;
  let partitions = print.value.publications.partitions;
  let count = partitions.reduce((s, d) => d.index != '' ? s + 1 : s, 0);
  publishedCount.value = count;
  emit('update:modelValue', print.value);
}

watch(() => props.news, (newNews) => {
  news.value = JSON.parse(JSON.stringify(newNews));
},{ deep: true });


onMounted(async () => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);

  publishedCount.value = props.print?.publications ? props.print.publications.partitions.reduce((s, d) => d.index != '' ? s + 1 : 0, 0) : 0;

  news.value = JSON.parse(JSON.stringify(props.news));
  print.value = JSON.parse(JSON.stringify(props.modelValue));

  if (tooltipContainer.value) {
    new bootstrap.Tooltip(tooltipContainer.value);
  }
  const nData = (await api.get(`prints/neighborhoods/${props.modelValue.city}`, authHeaders())).data;
  neighborhoodMap.value = new Map();
  nData.forEach((d) => neighborhoodMap.value.set(d.id, d.name));
  neighborhoodOptions.value = nData.map((d) => ({ text: d.name, value: d.id, id: d.id }));
  neighborhoodOptions.value = [{ text: 'All neighborhoods', value: 'all', id: '' }, ...neighborhoodOptions.value];
  template.value = (await api.get('templates/get', { params: { email: user.email } })).data;
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  multiSelectTheme.value = prefersDarkTheme.matches ? 'dark-mode' : '';
  darkMode.value = prefersDarkTheme.matches;
}
</script>

<style scoped>
code.trace {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: normal;
  /* min-height: 100px; */
  margin: 0 0 0 0px;
  padding: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  font-size: 0.72em;
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
}

.dark-mode :deep(.multiselect-wrapper) {
  background-color: #222529;
  color: white !important;
}

.dark-mode :deep(.multiselect-tags) {
  background-color: #222529;
  color: white !important;
}

.dark-mode :deep(.multiselect-options) {
  background-color: #222529;
  color: white !important;
}

.key {
  background-color: lightyellow;
}

.value {
  background-color: lightyellow;
}
</style>
