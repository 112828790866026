<template>
  <div class="text-center">
    <form class="form-signin" @submit.prevent="login()">
      <img class="mt-5" style="width: 230px" src="/crosstown.svg" alt="Crosstown Foundry logo" />
      <div class="logotype mb-4">Crosstown Foundry</div>

      <label for="inputEmail" class="visually-hidden">Email</label>
      <input id="inputEmail" class="form-control" placeholder="Email" required autofocus v-model="email"
        @input="resetMessage" />
      <label for="inputPassword" class="visually-hidden">Password</label>
      <input type="password" id="inputPassword" class="form-control" placeholder="Password" required v-model="password"
        @input="resetMessage" autocomplete="on" />

      <button class="w-100 btn btn-lg btn-secondary mt-3" type="submit">Sign in</button>

      <div class="btn btn-link mt-5" @click="router.push({ name: 'loginCode' })">Login with email code</div>
      <p class="mt-2 fw-bold text-danger">{{ message }}</p>
      <p id="footer" class="text-muted">&copy; 2020-23 Crosstown</p>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { api } from '../api';
import { useRouter } from 'vue-router';
const router = useRouter();

import { useUserStore } from '../stores/user';
const userStore = useUserStore();

const login = async () => {
  try {
    const response = await api
      .post('users/login', {
        email: email.value.trim(),
        password: password.value.trim(),
      });

    if (response.data.error) {
      message.value = result.data.error.message;
    } else {
      const { user, token } = response.data;
      localStorage.setItem('foundry.user.token', token);
      localStorage.setItem('foundry.user', JSON.stringify(user));
      userStore.setUser(user);
      router.push('/home');
    }
  } catch (error) {
    message.value = 'Login failed:' + error;
  }
};

const resetMessage = () => {
  message.value = '';
};

const email = ref('');
const password = ref('');
const message = ref('');
</script>

<style scoped>
body {
  min-height: 50vh;
}

#footer {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 0px 15px;
  margin: 0 auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin h1 {
  font-size: 6em;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.logotype {
  margin: 20px 0;
  font-size: 1.5em;
  font-weight: bolder;
}

#inputEmail {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
